import React, {useEffect, useState} from 'react'
import { DatePicker } from 'antd';
import moment from "moment";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

function DateFilter({element, value, onChange}) {
  const [filterValue, setFilterValue] = useState(new Date())
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    onChange(filterValue)
  }, [])

  useEffect(() => {
    if (value) {
      setFilterValue(value)
    }
  }, [value])

  const handlePanelChange = (value) => {
    onChange(value || '')
    setFilterValue(value)
    setIsOpen(false)
  }

  const handleOpenChange = (status) => {
    if(status){
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }

  return (
    <span className={'filter-item ' + (element.className || '')}>
      <DatePicker
        placeholder={element.placeholder}
        onChange={(value) => {
          onChange(value || '')
          setFilterValue(value)
        }}
        format={element.isMonth ? 'MM.YYYY' : 'DD.MM.YYYY'}
        value={moment(filterValue)}
        style={{
          width: 150,
        }}
        mode={element.isMonth ? 'month' : 'date'}
        onOpenChange={handleOpenChange}
        onPanelChange={handlePanelChange}
        open={isOpen}
        locale={locale}
      />
    </span>
  )
}

export default DateFilter