import React, {useEffect} from 'react'
import MenuClient from '../../../../Components/MenuClient'
import i18n from "i18next"
import apiUrls from "../../../../ApiUrls"
import useApiFetch from "../../../../Components/Hooks/useApiFetch"
import AgeTable from "./AgeTable";
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

function List() {
  const [{data: ages, isLoading: isAgesLoading}, getAges] = useApiFetch(apiUrls.get.ages())

  useEffect(() => {
    getAges()
  }, []);

  return (
    <Layout className="layout">
      <Header
        style={{
          padding: 0,
          top: 0,
          zIndex: 1,
          width: '100%',
          height: '120px',
          background: '#fff',
        }}
      >
        <MenuClient title={`${i18n.t('autopilot.list')} ${i18n.t('autopilot.age')}`}>
        </MenuClient>
      </Header>
      <Content>
        <AgeTable
          ages={ages}
          getAges={() => getAges()}
          loading={isAgesLoading}
        />
      </Content>
      <Footer style={{
        background: '#fff',
      }} />
    </Layout>
  );
}

export default List;