import React, {useEffect, useMemo, useState} from 'react';
import RowCell from "../../RowCell";
import {Table} from "antd";
import 'antd/dist/antd.css';

import i18n from "i18next";
import {cloneDeep, isEqual} from "lodash";
import useDelete from "../../../../Components/Hooks/useDelete";
import apiUrls from "../../../../ApiUrls";
import {toast} from "react-toastify";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";
import useApiPost from "../../../../Components/Hooks/useApiPost";

export default function CpmTable({cpms, platformOptions, getCpms, loading}) {
  const [cpmData, setCpmData] = useState(cpms)
  const [changedItems, setChangedItems] = useState([])
  const deleteCpm = useDelete(apiUrls.delete.cpm)
  const [{}, updateCpm] = useApiPatch(
    apiUrls.patch.cpm,
    '',
    'autopilot.cpm',
  )

  const [{}, addCpm] = useApiPost(
    apiUrls.post.cpm(),
    '',
    'autopilot.cpm',
  )

  useEffect(() => {
    if (cpms) {
      const cpmData = cloneDeep(cpms)
      setCpmData(cpmData)
    }
  }, [cpms])

  const onValueChange = (value, key, cpm, index) => {
    const cpmItems = cloneDeep(cpmData)
    let cpmItem = null
    if (cpmItems[index]) {
      cpmItem = cpmItems[index]
      if (key === 'platform') {
        cpmItems[index][key]['@id'] = `/digital_platforms/${value.value}`
        cpmItems[index][key].id = value.value
        cpmItems[index][key].name = value.label
      } else {
        cpmItems[index][key] = value
      }
    }

    setCpmData(cpmItems)

    if (cpm.id) {
      const oldCpm = cpms.find(item => item.id === cpm.id)
      const changed = cloneDeep(changedItems)

      if (isEqual(oldCpm, cpmItem)) {
        const index = changed.indexOf(cpm.id);
        if (index > -1) {
          changed.splice(index, 1)
        }
      } else {
        if (!changed.includes(cpm.id)) {
          changed.push(cpm.id)
        }
      }

      setChangedItems(changed)
    }
  }

  const addNewItem = () => {
    const cpmItems = cloneDeep(cpmData)
    cpmItems.push({
      dateStart: new Date(),
      id: '',
      platform: {
        '@type': "DigitalPlatform",
        id: '',
        name: '',
      },
      value: 0,
      viewMax: 0,
      viewMin: 0,
    })

    setCpmData(cpmItems)
  }

  const deleteItem = cpm => {
    deleteCpm(cpm.id, () => {
      toast.success(i18n.t('common.deleted'))
      getCpms()
    })
  }

  const saveItem = cpm => {
    if (cpm.id) {
      updateCpm(cpm.id, {
        dateStart: cpm.dateStart,
        platform: cpm.platform['@id'],
        value: cpm.value,
        viewMax: cpm.viewMax,
        viewMin: cpm.viewMin,
      }, {}, () => {
        getCpms()
        toast.success(i18n.t('common.saved'))

        const changed = cloneDeep(changedItems)
        const index = changed.indexOf(cpm.id)
        if (index > -1) {
          changed.splice(index, 1);
        }
        setChangedItems(changed)
      })
    } else {
      addCpm({
        dateStart: cpm.dateStart,
        platform: cpm.platform['@id'],
        value: cpm.value,
        viewMax: cpm.viewMax,
        viewMin: cpm.viewMin,
      }, null, {}, true, () => {
        getCpms()
        toast.success(i18n.t('common.saved'))
      })
    }
  }

  const data = useMemo(() => {
    const data = []

    const columns = [
      {
        key: 'id',
        editable: false,
        type: 'int',
      },
      {
        key: 'platform',
        editable: true,
        type: 'select',
        value: value => ({
          label: value.name,
          value: value.id,
        }),
        options: platformOptions,
      },
      {
        key: 'value',
        editable: true,
        type: 'int',
      },
      {
        key: 'viewMax',
        editable: true,
        type: 'int',
      },
      {
        key: 'viewMin',
        editable: true,
        type: 'int',
      },
      {
        key: 'dateStart',
        editable: true,
        type: 'date',
      },
      {
        key: 'actions',
        editable: false,
        type: 'actions',
      },
    ]

    if (cpmData) {
      for (let index in cpmData) {
        const cpm = cpmData[index]
        const item = {}
        for (let column of columns) {
          const value = column.value ? column.value(cpm[column.key]) : cpm[column.key]

          if (column.key === 'actions') {
            item[column.key] =
              <div className={'autopilot-table__buttons'}>
                <DeleteWithConfirmButton onClick={() =>
                  deleteItem(cpm)} title={i18n.t('common.delete')}/>

                {(changedItems.includes(cpm.id) || !cpm.id) &&
                  <button
                    onClick={() => saveItem(cpm)}
                    title={i18n.t('common.save')}
                  >
                    <i className="far fa-save"/>
                  </button>
                }
              </div>
            continue
          } else if (!column.editable) {
            item[column.key] = value
            continue
          }

          item[column.key] = <RowCell
            key={column.key}
            value={value}
            onChange={value => {
              onValueChange(value, column.key, cpm, index)
            }}
            code={column.key}
            type={column.type}
            options={column.options}
          />
        }

        data.push(item)
      }
    }

    return data
  }, [cpmData])

  const columns = [
    {
      title: i18n.t('autopilot.id'),
      width: 100,
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.platform'),
      width: 200,
      dataIndex: 'platform',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.dateStart'),
      width: 100,
      dataIndex: 'dateStart',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.viewMin'),
      width: 100,
      dataIndex: 'viewMin',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.viewMax'),
      width: 100,
      dataIndex: 'viewMax',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.value'),
      width: 100,
      dataIndex: 'value',
      align: 'center',
    },
    {
      title: '',
      width: 30,
      dataIndex: 'actions',
      align: 'center',
    },
  ]

  return <div className={'autopilot-table'}>
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowClassName={(record) => (record.isNew ? 'new-row' : '')}
      loading={loading}
    />
    <a className="blue-cursor-button" onClick={addNewItem}>
      <i className="fal fa-plus mr-for-icon"></i>
      {i18n.t('common.add')}
    </a>
  </div>

}