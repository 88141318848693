import React from 'react';
import i18n from "i18next";
import moment from "moment";
import { DatePicker } from 'antd';
import { InputNumber } from 'antd';
import { Select } from 'antd';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

const floatType = 'float'
const intType = 'int'
const dateType = 'date'
const selectType = 'select'

export default function RowCell({value, type, onChange, code, options, disabled = false}) {
  return (
    <div className={'money'}>
      <div className={'budget-cell'}>
        {type === floatType &&
          <InputNumber
            style={{
              width: 200,
            }}
            min={0}
            precision={options.precision || 2}
            onChange={onChange}
            value={value}
          />
        }

        {type === intType &&
          <InputNumber
            onChange={(value) => onChange(value, code)}
            value={value}
            style={{
              width: 150,
            }}
            min={0}
            max={2147483647}
          />
        }

        {type === dateType &&
          <DatePicker
            onChange={(value) => onChange(value, code)}
            format={'DD.MM.YYYY'}
            value={moment(value)}
            style={{
              width: 150,
            }}
            locale={locale}
          />
        }

        {type === selectType &&
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={i18n.t('invoice.edit.select_legal')}
            optionFilterProp="label"
            filterOption={(input, option) => option.label.includes(input)}
            options={options}
            value={value}
            onChange={(value, option) => onChange(option, code)}
            status={(!value || !value.value) ? 'error' : ''}
          />
        }
      </div>
    </div>
  )
}