import React, {useEffect, useState} from 'react'
import MenuClient from '../../../../Components/MenuClient'
import i18n from "i18next"
import apiUrls from "../../../../ApiUrls"
import useApiFetch from "../../../../Components/Hooks/useApiFetch"
import Filter from "../../../../Components/NewFilter/Filter";
import {groupParamsByKey} from "../../../../utils";
import HistoricalPerformanceTable from "./HistoricalPerformanceTable";
import {Layout, Button, Col, Row, Form, Upload, Modal, Space} from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import xlsServiceProvider from "../../../../api/xlsServiceProvider";
import {toast} from "react-toastify";
import responseStatuses from "../../../../api/responseStatuses";
const { Header, Content, Footer } = Layout;

const uploadDataMatchingErrors = 'Ошибки соответствия данных'
const uploadDataWritingErrors = 'Ошибки во время записи данных'

function List() {
  const history = useHistory()
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const [filterParams, setFilterParams] = useState('')
  const [page, setPage] = useState(queryParameters.get("page") || 1)
  const [{data: historicalPerformances, isLoading: isHistoricalPerformanceLoading, total: historicalPerformanceTotal}, getHistoricalPerformance]
    = useApiFetch(apiUrls.get.historicalPerformance())

  const [isFileModalOpen, setIsFileModalOpen] = useState(false)
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [fileForm] = Form.useForm()
  const [uploadResponse, setUploadResponse] = useState(null)
  const [sortParams, setSortParams] = useState({})

  const filters = {
    product: {
      type: 'select',
      value: null,
      placeholder: i18n.t('autopilot.product'),
      getOptionsUrlFunction: apiUrls.get.productsWithoutPagination,
      name: 'product',
      setFilterParams: (params, values) => {
        params.delete('product.id[]')
        for (let filterValue of values) {
          params.append('product.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, platform) => {
        if (platform && platform.length) {
          params.delete('product[]')
          for (let platformItem of platform) {
            params.append('product[]', platformItem.value)
          }
        } else {
          params.delete('product[]')
        }
      },
      isMulti: true,
      width: 300,
    },
    format: {
      type: 'select',
      value: null,
      placeholder: i18n.t('autopilot.format'),
      getOptionsUrlFunction: apiUrls.get.digitalFormats,
      name: 'format',
      setFilterParams: (params, values) => {
        params.delete('format.id[]')
        for (let filterValue of values) {
          params.append('format.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, format) => {
        if (format && format.length) {
          params.delete('format[]')
          for (let formatItem of format) {
            params.append('format[]', formatItem.value)
          }
        } else {
          params.delete('format[]')
        }
      },
      isMulti: true,
      width: 300,
    },
    channel: {
      type: 'select',
      value: null,
      placeholder: i18n.t('autopilot.channel'),
      getOptionsUrlFunction: apiUrls.get.digitalChannels,
      name: 'channel',
      setFilterParams: (params, values) => {
        params.delete('channel.id[]')
        for (let filterValue of values) {
          params.append('channel.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, channel) => {
        if (channel && channel.length) {
          params.delete('channel[]')
          for (let channelItem of channel) {
            params.append('channel[]', channelItem.value)
          }
        } else {
          params.delete('channel[]')
        }
      },
      isMulti: true,
      width: 300,
    },
    platform: {
      type: 'select',
      value: null,
      placeholder: i18n.t('autopilot.platform'),
      getOptionsUrlFunction: apiUrls.get.digitalPlatforms,
      name: 'platform',
      setFilterParams: (params, values) => {
        params.delete('platform.id[]')
        for (let filterValue of values) {
          params.append('platform.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, platform) => {
        if (platform && platform.length) {
          params.delete('platform[]')
          for (let platformItem of platform) {
            params.append('platform[]', platformItem.value)
          }
        } else {
          params.delete('platform[]')
        }
      },
      isMulti: true,
      width: 300,
    },
  }

  useEffect(() => {
    if (filterParams) {
      getHistoricalPerformance(getDataParams())
    }

    if (page) {
      savePageToUrl()
    }
  }, [filterParams, page, sortParams])

  const getDataParams = () => {
    const params = {
      't[]': ['product', 'hist', 'face_platform', 'face_channel', 'format'],
      page,
    }

    let filters = {}

    if (filterParams) {
      filters = groupParamsByKey(filterParams)
    }

    const sorter = {}

    for (let key in sortParams) {
      const direction = sortParams[key]

      if (['ascend', 'descend'].includes(direction)) {
        sorter[`order[${key}.id]`] = direction === 'ascend' ? 'asc' : 'desc'
      }
    }

    return {...params, ...filters, ...sorter}
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  const savePageToUrl = () => {
    let params = new URLSearchParams(history.location.search)

    if (page) {
      params.set('page', page)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const beforeUpload = (file) => {
    return false;
  }

  const onFileFormSubmit = values => {
    const request = xlsServiceProvider.postProductChannels;

    if (!request) {
      toast.error(i18n.t('server_error'));
    }

    setIsFileLoading(true)

    const formData = new FormData()

    formData.append('file', values.file.file, values.file.file.name)

    request(formData).then(response => {
      if (response.ok) {
        response.json()
          .then(data => {
            if (data.message && data.report) {
              setUploadResponse(data)
            }
          })
      }

      if (response.status >= responseStatuses.SERVER_ERROR_STATUS) {
        toast.error(i18n.t('invoice.edit.get_xls_error'));
      }

    }).catch((e) => {
      toast.error(i18n.t('server_error'));
    }).finally(() => {
      setIsFileLoading(false)
    })
  }

  return (
    <Layout className="layout">
      <Header
        style={{
          padding: 0,
          top: 0,
          zIndex: 1,
          width: '100%',
          height: '168px',
          background: '#fff',
        }}
      >
        <MenuClient title={`${i18n.t('autopilot.list')} ${i18n.t('autopilot.product_channel')}`}></MenuClient>
        <Row style={{
          lineHeight: 0,
        }}>
          <Col flex="720px">
            <Filter
              filters={Object.values(filters)}
              getData={setParams}
              delay={500}
              setPage={setPage}
            />
          </Col>
          <Col flex="auto">
            <Space>
              <Button icon={<UploadOutlined />} onClick={() => setIsFileModalOpen(true)}/>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content>
        <HistoricalPerformanceTable
          historicalPerformances={historicalPerformances}
          total={historicalPerformanceTotal}
          getHistoricalPerformances={() => getHistoricalPerformance(getDataParams())}
          loading={isHistoricalPerformanceLoading}
          setPage={setPage}
          page={page}
          sortParams={sortParams}
          setSortParams={setSortParams}
        />
      </Content>
      <Footer style={{
        background: '#fff',
      }} />

      <Modal
        title={i18n.t('autopilot.upload')}
        centered
        visible={isFileModalOpen}
        onOk={() => setIsFileModalOpen(false)}
        onCancel={() => setIsFileModalOpen(false)}
        width={800}
        footer={[]}
      >
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          layout="horizontal"
          form={fileForm}
          onFinish={onFileFormSubmit}
          //onValuesChange={onValuesChange}
        >
          <Form.Item label={i18n.t('autopilot.file')} name="file" rules={[
            {
              required: true,
              message: i18n.t('autopilot.choose_file'),
            },
          ]}>
            <Upload beforeUpload={beforeUpload}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {i18n.t('common.save')}
            </Button>
          </Form.Item>
        </Form>
        {uploadResponse &&
          <div className={'upload-data-wrapper'}>
            <div className={'upload-data-wrapper__message'}>
              {uploadResponse.message}
            </div>
            {/*{uploadResponse.report && uploadResponse.report.success && uploadResponse.report.success.Created &&
              <AdvertisingsList
                title={i18n.t('financial.created') + ':'}
                advertisings={uploadResponse.report.success.Created}
              />
            }
            {uploadResponse.report && uploadResponse.report.success && uploadResponse.report.success.Updated &&
              <AdvertisingsList
                title={i18n.t('financial.updated') + ':'}
                advertisings={uploadResponse.report.success.Updated}
              />
            }

            {uploadResponse.report && uploadResponse.report.errors && uploadResponse.report.errors[uploadDataMatchingErrors] &&
              <AdvertisingMatchingErrors
                title={uploadDataMatchingErrors + ':'}
                errors={(Object.entries(uploadResponse.report.errors[uploadDataMatchingErrors]))}
              />
            }

            {uploadResponse.report && uploadResponse.report.errors && uploadResponse.report.errors[uploadDataWritingErrors] &&
              <AdvertisingWritingErrors
                title={uploadDataWritingErrors + ':'}
                errors={uploadResponse.report.errors[uploadDataWritingErrors]}
              />
            }*/}
          </div>
        }
      </Modal>
    </Layout>
  );
}

export default List;