import React, {useEffect, useMemo, useState} from 'react';
import RowCell from "../../RowCell";
import {Button, Modal, Table, Form, InputNumber, Select} from "antd";
import 'antd/dist/antd.css';

import i18n from "i18next";
import {cloneDeep, isEqual} from "lodash";
import useDelete from "../../../../Components/Hooks/useDelete";
import apiUrls from "../../../../ApiUrls";
import {toast} from "react-toastify";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";
import useApiPost from "../../../../Components/Hooks/useApiPost";

const formChannelFields = [
  {
    title: 'Media: OLV (2)',
    id: '/digital_channels/2',
  },
  {
    title: 'Media: Classic (3)',
    id: '/digital_channels/3',
  },
  {
    title: 'Media: Mobile (11)',
    id: '/digital_channels/11',
  },
  {
    title: 'Media: Programmatic (10)',
    id: '/digital_channels/10',
  },
  {
    title: 'Performance: CPA (7)',
    id: '/digital_channels/7',
  },
  {
    title: 'Performance: STA (4)',
    id: '/digital_channels/4',
  },
  {
    title: 'Performance: Mobile (8)',
    id: '/digital_channels/8',
  },
  {
    title: 'Performance: SEA (5)',
    id: '/digital_channels/5',
  },
]

const columns = [
  {
    title: i18n.t('autopilot.id'),
    width: 100,
    dataIndex: 'id',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.product'),
    width: 200,
    dataIndex: 'product',
    align: 'center',
    editable: false,
    render: (text, record, index) => record.product.name,
  },
  {
    title: i18n.t('autopilot.mrmCode'),
    width: 100,
    dataIndex: 'product',
    align: 'center',
    editable: false,
    render: (text, record, index) => record.product.mrmCode,
  },
]

for (let field of formChannelFields) {
  columns.push({
    title: field.title,
    dataIndex: field.id,
    width: 100,
    align: 'center',
    editable: true,
    options: {
      precision: 15,
    },
    type: 'float',
  })
}

columns.push({
  title: '',
  width: 30,
  dataIndex: 'actions',
  align: 'center',
  editable: false,
  type: 'actions',
})

export default function ProductChannelsTable({productChannels, getProductChannels, loading, total, setPage,
                                               page, companyProductsOptions, isModalOpen, setModalOpen}) {
  const [productChannelData, setProductChannelData] = useState([])
  const [changedItems, setChangedItems] = useState([])
  const [form] = Form.useForm();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: Number(page),
      pageSize: 30,
      total,
    },
  });

  const deleteProductChannel = useDelete(apiUrls.delete.productChannel)
  const [{}, updateProductChannel] = useApiPatch(
    apiUrls.patch.productChannel,
    '',
    'autopilot.productChannel',
  )

  const [{}, addProductChannel] = useApiPost(
    apiUrls.post.productChannel(),
    '',
    'autopilot.productChannel',
  )

  useEffect(() => {
    if (productChannels) {
      const productChannelData = cloneDeep(productChannels)

      for (let item of productChannelData) {
        for (let share of item.shares) {
          item[share.channel['@id']] = share.share
        }
      }

      setProductChannelData(productChannelData)
    }
  }, [productChannels])

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [total]);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: Number(page),
      },
    });
  }, [page])

  const onValueChange = (value, key, productChannel, index) => {
    const productChannelItems = cloneDeep(productChannelData)
    let productChannelItem = null
    if (productChannelItems[index]) {
      productChannelItem = productChannelItems[index]

      productChannelItems[index][key] = value

      const share = productChannelItem.shares.find(item => item.channel['@id'] === key)

      if (share) {
        share.share = value
      } else {
        const channel = formChannelFields.find(field => field.id === key)

        if (channel) {
          productChannelItem.shares.push({
            channel: {
              '@id': key,
              '@type': "DigitalChannel",
              id: Number(key.split('/').pop()),
              name: channel.name,

            },
            share: value,
          })
        }
      }
    }

    setProductChannelData(productChannelItems)

    if (productChannel.id) {
      const oldProductChannel = productChannels.find(item => item.id === productChannel.id)
      const changed = cloneDeep(changedItems)

      if (isEqual(oldProductChannel, productChannelItem)) {
        const index = changed.indexOf(productChannel.id);
        if (index > -1) {
          changed.splice(index, 1)
        }
      } else {
        if (!changed.includes(productChannel.id)) {
          changed.push(productChannel.id)
        }
      }

      setChangedItems(changed)
    }
  }

  const deleteItem = productChannel => {
    deleteProductChannel(productChannel.id, () => {
      toast.success(i18n.t('common.deleted'))
      getProductChannels()
    })
  }

  const saveItem = productChannel => {
    const data = {
      product: productChannel.product['@id'],
      shares: [],
    }

    let sharesTotal = 0
    for (let share of productChannel.shares) {
      sharesTotal += share.share
    }

    if( Math.abs(1 - sharesTotal) >= 0.01) {
      toast.error(i18n.t('autopilot.product_channels_total_error'))
      return
    }

    for (let share of productChannel.shares) {
      data.shares.push({
        channel: share.channel['@id'],
        share: share.share,
      })
    }

    if (productChannel.id) {
      updateProductChannel(productChannel.id, data, {}, () => {
        getProductChannels()
        toast.success(i18n.t('common.saved'))

        const changed = cloneDeep(changedItems)
        const index = changed.indexOf(productChannel.id)
        if (index > -1) {
          changed.splice(index, 1);
        }
        setChangedItems(changed)
      })
    } else {
      addProductChannel(data, null, {}, true, () => {
        getProductChannels()
        toast.success(i18n.t('common.saved'))
      })
    }
  }

  const data = useMemo(() => {
    const data = []

    if (productChannelData) {
      for (let index in productChannelData) {
        const productChannel = productChannelData[index]
        const item = {}
        for (let column of columns) {
          const value = column.value ? column.value(productChannel[column.dataIndex]) : productChannel[column.dataIndex]

          if (column.dataIndex === 'actions') {
            item[column.dataIndex] =
              <div className={'autopilot-table__buttons'}>
                <DeleteWithConfirmButton onClick={() =>
                  deleteItem(productChannel)} title={i18n.t('common.delete')}/>

                {(changedItems.includes(productChannel.id) || !productChannel.id) &&
                  <button
                    onClick={() => saveItem(productChannel)}
                    title={i18n.t('common.save')}
                  >
                    <i className="far fa-save"/>
                  </button>
                }
              </div>
            continue
          } else if (!column.editable) {
            item[column.dataIndex] = value
            continue
          }

          item[column.dataIndex] = <RowCell
            key={column.dataIndex}
            value={value}
            onChange={value => {
              onValueChange(value, column.dataIndex, productChannel, index)
            }}
            code={column.dataIndex}
            type={column.type}
            options={column.options}
          />
        }

        data.push(item)
      }
    }

    return data
  }, [productChannelData])

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current)
    setTableParams({
      pagination
    })
  }

  const onFinish = (values) => {
    let channelSharesTotal = 0
    for (let channel of formChannelFields) {
      if (values.hasOwnProperty(channel.id) && values[channel.id]) {
        channelSharesTotal += Number(values[channel.id])
      }
    }

    if( Math.abs(1 - channelSharesTotal) >= 0.01) {
      toast.error(i18n.t('autopilot.product_channels_total_error'))

      formChannelFields.map(field => {
        form.setFields([
          {
            name: field.id,
            errors: [i18n.t('autopilot.product_channels_total_error')],
          },
        ])
      })

      return
    }

    const data = {
      product: `products/${values.product}`,
      shares: [],
    }

    for (let channel of formChannelFields) {
      if (values.hasOwnProperty(channel.id) && values[channel.id]) {
        data.shares.push({
          channel: channel.id,
          share: values[channel.id]
        })
      }
    }

    addProductChannel(data, null, {}, true, () => {
      getProductChannels()
      toast.success(i18n.t('common.saved'))
      setModalOpen(false)
    })
  }

  const onValuesChange = values => {
    formChannelFields.map(field => {
      form.setFields([
        {
          name: field.id,
          errors: [],
        },
      ])
    })
  }

  return <div className={'autopilot-table'}>

    <Table
      columns={columns}
      dataSource={data}
      pagination={tableParams.pagination}
      rowClassName={(record) => (record.isNew ? 'new-row' : '')}
      loading={loading}
      onChange={handleTableChange}
    />
    <Modal
      title={i18n.t('common.add')}
      centered
      visible={isModalOpen}
      onOk={() => setModalOpen(false)}
      onCancel={() => setModalOpen(false)}
      width={800}
      footer={[]}
    >
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        layout="horizontal"
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item label={i18n.t('autopilot.product')} name="product" rules={[
          {
            required: true,
            message: i18n.t('autopilot.chooseProduct'),
          },
        ]}>
          <Select
            showSearch
            optionFilterProp="label"
            options={companyProductsOptions}
          />
        </Form.Item>
        {formChannelFields.map(field => (
          <Form.Item label={field.title} name={field.id}>
            <InputNumber
              style={{
                width: 200,
              }}
              min={0}
              precision={15}
            />
          </Form.Item>
        ))}
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            {i18n.t('common.save')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  </div>

}