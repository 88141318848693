import React, {useEffect, useState} from 'react'
import {isEqual} from "lodash";
import {api} from "../../../api/apiProvider";
import {getValuesFromUrl} from "../../../utils";
import usePrevious from "../../Hooks/usePrevious";
import i18next from "i18next";
import { Select } from 'antd';

function SelectFilter({element, value, isDisabled = () => {}, onChange, setFilterValue, filterValues, optionValues = null}) {
  const [options, setOptions] = useState(optionValues)
  const previous = usePrevious({filterValues})

  useEffect(() => {
    if ((element.depends === undefined || element.firstNotWaitDepend) && typeof element.getOptionsUrlFunction === 'function') {
      getOptions(element.getOptionsUrlFunction(filterValues))
    }
  },[])

  useEffect(() => {
    if (options !== null) {
      setValueFromUrl()
    }

    if (typeof element.setOptions === 'function') {
      element.setOptions(options, onChanged)
    }
  }, [options])

  useEffect(() => {
    if (element.depends) {
      let values = {}

      for (let name of element.depends) {
        values[name] = (filterValues[name] === null || filterValues[name] === undefined) ? null : filterValues[name]
      }

      const isValues = Object.values(values).every(value => value)

      if (!isValues) {
        return
      }

      let isChanged = false

      for (let name of element.depends) {
        const value = filterValues[name]
        const oldValue = previous.filterValues[name]

        if (!isEqual(value, oldValue)) {
          isChanged = true
        }
      }

      if (isChanged && typeof element.getOptionsUrlFunction === 'function') {
        getOptions(element.getOptionsUrlFunction(filterValues))
      }
    }

  }, [filterValues])

  const getOptions = url => {
    if (!url) {
      return
    }

    api.get(url).then((data) => {
      const responseData = data['hydra:member']
      if (typeof element.setOptionValues === 'function') {
        element.setOptionValues(responseData, onChanged)
      }

      const options = responseData
        ? responseData.map((item) => {
          const optionValueName = element.optionValueName || 'id'

          return {value: item[optionValueName], label: item.name, code: item.code}
        })
        : []

      if (element.isEmptyOption) {
        const emptyOption = {
          label: i18next.t('projects.list.not_selected'),
          value: ''
        }

        options.unshift(emptyOption)
      } else if (element.additionalOptions) {
        for (let option of element.additionalOptions) {
          options.unshift(option)
        }
      }

      setOptions(options)
    })
  }

  const setValueFromUrl = () => {
    const values = getValuesFromUrl()
    const valueFromUrl = values[element.name] || values[element.name + '[]']

    if (valueFromUrl && valueFromUrl.length) {
      const filterValues = valueFromUrl.map(value => !isNaN(value) ? parseInt(value) : value)
      const values = options.filter(option => filterValues.includes(option.value))

      let value = element.isMulti ? values : values[0]

      setFilterValue(element.name, value)
    } else {
      setFilterValue(element.name, element.isMulti ? [] : '')
    }
  }

  const onChanged = (value) => {
    if (Array.isArray(value)) {
      const selectAllOption = value.find(item => item.value === 'all')

      if (selectAllOption) {
        onChange([selectAllOption])
        return
      }
    }

    if (value === null) {
      value = element.isMulti ? [] : ''
    }

    onChange(value)
  }

  return (
    <span className={'filter-item ' + ((element.required && (!value || (Array.isArray(value) && !value.length))) ? 'error' : '')}>
      <Select
        showSearch
        mode={element.isMulti ? 'tags' : ''}
        style={{ width: element.width || 200 }}
        placeholder={element.placeholder}
        optionFilterProp="label"
        filterOption={(input, option) => option.label.includes(input)}
        options={options === null ? [] : options}
        value={value}
        onChange={(value, option) => onChanged(option)}
        disabled={typeof isDisabled === 'function' ? isDisabled(filterValues) : false}
        status={(element.required && (!value || (Array.isArray(value) && !value.length))) ? 'error' : ''}
      />
      </span>
  )
}

export default SelectFilter