import {toast} from "react-toastify";
import i18n from "i18next";
import responseStatuses from './responseStatuses'
import keycloak from '../keycloak'

const xlsApiProtocol = process.env.REACT_APP_PROTOCOL_XLS
const xlsApiHost = process.env.REACT_APP_HOST_XLS
const apiPoint = process.env.REACT_APP_API_ENTRYPOINT

const xlsApiPoint = `${xlsApiProtocol}://${xlsApiHost}`

const invoicePoint = process.env.REACT_APP_XLS_GET_INVOICE
const prefinancialPoint = process.env.REACT_APP_XLS_GET_PREFINANCIAL
const getAdvertisngPoint = process.env.REACT_APP_XLS_GET_ADVERTISING
const postDigitalPlanPoint = process.env.REACT_APP_XLS_POST_DIGITAL_PLAN
const postInternetPlanPoint = process.env.REACT_APP_XLS_POST_INTERNET_PLAN
const postTvPlanPoint = process.env.REACT_APP_XLS_POST_TV_PLAN
const postMonthFactPoint = process.env.REACT_APP_XLS_POST_MONTH_FACT_PLAN
const postProductChannelsPoint = process.env.REACT_APP_XLS_POST_PRODUCT_CHANNELS

const get = async (url) => {
  const response = await fetch(xlsApiPoint + url, {
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    }
  })

  if (!response.ok) {
    showResponseErrors(response)
    throw new Error('request error')
  }

  return response
}

const post = async (url, data) => {
  return await fetch(xlsApiPoint + url, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${keycloak.token}`,
    }
  })
}

const showResponseErrors = (response) => {
  if (response.status >= responseStatuses.SERVER_ERROR_STATUS) {
    toast.error(i18n.t('invoice.edit.get_xls_error'));
  }

  if (response.status < responseStatuses.SERVER_ERROR_STATUS && response.status >= responseStatuses.BAD_REQUEST_STATUS) {
    response.json()
      .then(data => {
        if (data && data.error) {
          toast.error(data.error);
        }
      })
  }
}

export default {
  /**
   * @param invoiceId
   * @returns {Promise<*>}
   */
  getForInvoice: async invoiceId => {
    const params = new URLSearchParams({
      api: apiPoint,
      invoice: invoiceId
    })

    return get(`${invoicePoint}?${params.toString()}`)
  },
  /**
   * @param params URLSearchParams
   * @returns {Promise<*>}
   */
  getForPrefinancial: async params => {
    params.set('api', apiPoint)
    return get(`${prefinancialPoint}?${params.toString()}`)
      .catch(error => {
        console.error(error)
      })
  },
  /**
   * @param advertisingId
   * @returns {Promise<*>}
   */
  getForAdvertisng: async (advertisingId) => {
    const params = new URLSearchParams();
    params.append('api', apiPoint);
    return get(`${getAdvertisngPoint}${advertisingId}?${params.toString()}`);
  },
  /**
   *
   * @param formData
   * @returns {Promise<Response>}
   */
  postDigitalPlan: async formData => {
    const urlParams = new URLSearchParams({
      api: apiPoint
    })

    return post(`${postDigitalPlanPoint}?${urlParams.toString()}`, formData)
  },

  /**
   *
   * @param formData
   * @returns {Promise<Response>}
   */
  postInternetPlan: async formData => {
    const urlParams = new URLSearchParams({
      api: apiPoint
    })

    return post(`${postInternetPlanPoint}?${urlParams.toString()}`, formData)
  },

  /**
   *
   * @param formData
   * @returns {Promise<Response>}
   */
  postTvPlan: async formData => {
    const urlParams = new URLSearchParams({
      api: apiPoint
    })

    return post(`${postTvPlanPoint}?${urlParams.toString()}`, formData)
  },

  /**
   *
   * @param formData
   * @returns {Promise<Response>}
   */
  postMonthFact: async formData => {
    const urlParams = new URLSearchParams({
      api: apiPoint
    })

    return post(`${postMonthFactPoint}?${urlParams.toString()}`, formData)
  },

  postProductChannels: async formData => {
    const urlParams = new URLSearchParams({
      api: apiPoint
    })

    return post(`${postProductChannelsPoint}?${urlParams.toString()}`, formData)
  },
}
