import React, {useEffect, useState} from 'react'
import MenuClient from '../../../../Components/MenuClient'
import i18n from "i18next"
import apiUrls from "../../../../ApiUrls"
import useApiFetch from "../../../../Components/Hooks/useApiFetch"
import Filter from "../../../../Components/NewFilter/Filter";
import {groupParamsByKey} from "../../../../utils";
import ProductChannelsTable from "./ProductChannelsTable";
import {Layout, Button, Col, Row, Form, Upload, Modal, Space} from 'antd';
import {useHistory, useLocation} from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import xlsServiceProvider from "../../../../api/xlsServiceProvider";
import {toast} from "react-toastify";
import responseStatuses from "../../../../api/responseStatuses";
const { Header, Content, Footer } = Layout;

const uploadDataMatchingErrors = 'Ошибки соответствия данных'
const uploadDataWritingErrors = 'Ошибки во время записи данных'
const dateFormat = 'Y-MM-DD'

function List() {
  const history = useHistory()
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const [filterParams, setFilterParams] = useState('')
  const [page, setPage] = useState(queryParameters.get("page") || 1)
  const [{data: productChannels, isLoading: isProductChannelsLoading, total: productChannelsTotal}, getProductChannels]
    = useApiFetch(apiUrls.get.productChannels())
  const [companyProductsOptions, setCompanyProductsOptions] = useState([])
  const [isFormModalOpen, setIsFormModalOpen] = useState(false)
  const [isFileModalOpen, setIsFileModalOpen] = useState(false)
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [fileForm] = Form.useForm()
  const [uploadResponse, setUploadResponse] = useState(null)

  const filters = {
    product: {
      type: 'select',
      value: null,
      placeholder: i18n.t('autopilot.product'),
      getOptionsUrlFunction: () => {
        const params = new URLSearchParams({
          'exists[mrmCode]': true,
          pagination: false,
        })
        return apiUrls.get.companyProducts(2) + '?' + params.toString()
      },
      name: 'product',
      setFilterParams: (params, values) => {
        params.delete('product.id[]')
        for (let filterValue of values) {
          params.append('product.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, platform) => {
        if (platform && platform.length) {
          params.delete('product.id[]')
          for (let platformItem of platform) {
            params.append('product.id[]', platformItem.value)
          }
        } else {
          params.delete('product.id[]')
        }
      },
      setOptions: options => setCompanyProductsOptions(options),
      isMulti: true,
      width: 300,
    },
    name: {
      type: 'text',
      placeholder: i18n.t('autopilot.mrmCode'),
      name: 'mrmCode',
      getValueFromUrl: values => {
        if (values.mrmCode) {
          return values.mrmCode
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        value && params.set('mrmCode', value)
      },
      setFilterParams: (params, filterValue) => {
        filterValue && params.append('product.mrmCode', filterValue)
      },
      className: 'autopilot-text-filter',
    },
  }

  useEffect(() => {
    if (filterParams) {
      getProductChannels(getDataParams())
    }

    if (page) {
      savePageToUrl()
    }
  }, [filterParams, page])

  const getDataParams = () => {
    const params = {
      't[]': ['face_platform'],
      page,
    }

    let filters = {}

    if (filterParams) {
      filters = groupParamsByKey(filterParams)
    }


    return {...params, ...filters}
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  const savePageToUrl = () => {
    let params = new URLSearchParams(history.location.search)

    if (page) {
      params.set('page', page)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const beforeUpload = (file) => {
    return false;
  }

  const onFileFormSubmit = values => {
    console.log(values)
    const request = xlsServiceProvider.postProductChannels;

    if (!request) {
      toast.error(i18n.t('server_error'));
    }

    setIsFileLoading(true)

    const formData = new FormData()

    formData.append('file', values.file.file, values.file.file.name)

    request(formData).then(response => {
      if (response.ok) {
        response.json()
          .then(data => {
            if (data.message && data.report) {
              setUploadResponse(data)
            }
          })
      }

      if (response.status >= responseStatuses.SERVER_ERROR_STATUS) {
        toast.error(i18n.t('invoice.edit.get_xls_error'));
      }

    }).catch((e) => {
      toast.error(i18n.t('server_error'));
    }).finally(() => {
      setIsFileLoading(false)
    })
  }

  return (
    <Layout className="layout">
      <Header
        style={{
          padding: 0,
          top: 0,
          zIndex: 1,
          width: '100%',
          height: '120px',
          background: '#fff',
        }}
      >
        <MenuClient title={`${i18n.t('autopilot.list')} ${i18n.t('autopilot.product_channel')}`}></MenuClient>
        <Row style={{
          lineHeight: 0,
        }}>
          <Col flex="720px">
            <Filter
              filters={Object.values(filters)}
              getData={setParams}
              delay={500}
              setPage={setPage}
            />
          </Col>
          <Col flex="auto">
            <Space>
              <Button type="primary" onClick={() => setIsFormModalOpen(true)}>{i18n.t('common.add')}</Button>
              <Button icon={<UploadOutlined />} onClick={() => setIsFileModalOpen(true)}/>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content>
        <ProductChannelsTable
          productChannels={productChannels}
          total={productChannelsTotal}
          getProductChannels={() => getProductChannels(getDataParams())}
          loading={isProductChannelsLoading}
          setPage={setPage}
          page={page}
          isModalOpen={isFormModalOpen}
          setModalOpen={setIsFormModalOpen}
          companyProductsOptions={companyProductsOptions}
        />
      </Content>
      <Footer style={{
        background: '#fff',
      }} />

      <Modal
        title={i18n.t('autopilot.upload')}
        centered
        visible={isFileModalOpen}
        onOk={() => setIsFileModalOpen(false)}
        onCancel={() => setIsFileModalOpen(false)}
        width={800}
        footer={[]}
      >
        <Form
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          layout="horizontal"
          form={fileForm}
          onFinish={onFileFormSubmit}
          //onValuesChange={onValuesChange}
        >
          <Form.Item label={i18n.t('autopilot.file')} name="file" rules={[
            {
              required: true,
              message: i18n.t('autopilot.choose_file'),
            },
          ]}>
            <Upload beforeUpload={beforeUpload}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {i18n.t('common.save')}
            </Button>
          </Form.Item>
        </Form>
        {uploadResponse &&
          <div className={'upload-data-wrapper'}>
            <div className={'upload-data-wrapper__message'}>
              {uploadResponse.message}
            </div>
            {/*{uploadResponse.report && uploadResponse.report.success && uploadResponse.report.success.Created &&
              <AdvertisingsList
                title={i18n.t('financial.created') + ':'}
                advertisings={uploadResponse.report.success.Created}
              />
            }
            {uploadResponse.report && uploadResponse.report.success && uploadResponse.report.success.Updated &&
              <AdvertisingsList
                title={i18n.t('financial.updated') + ':'}
                advertisings={uploadResponse.report.success.Updated}
              />
            }

            {uploadResponse.report && uploadResponse.report.errors && uploadResponse.report.errors[uploadDataMatchingErrors] &&
              <AdvertisingMatchingErrors
                title={uploadDataMatchingErrors + ':'}
                errors={(Object.entries(uploadResponse.report.errors[uploadDataMatchingErrors]))}
              />
            }

            {uploadResponse.report && uploadResponse.report.errors && uploadResponse.report.errors[uploadDataWritingErrors] &&
              <AdvertisingWritingErrors
                title={uploadDataWritingErrors + ':'}
                errors={uploadResponse.report.errors[uploadDataWritingErrors]}
              />
            }*/}
          </div>
        }
      </Modal>
    </Layout>
  );
}

export default List;