import React, {useEffect, useMemo, useState} from 'react';
import RowCell from "../../RowCell";
import {Table} from "antd";
import 'antd/dist/antd.css';
import i18n from "i18next";
import {cloneDeep, isEqual} from "lodash";
import useDelete from "../../../../Components/Hooks/useDelete";
import apiUrls from "../../../../ApiUrls";
import {toast} from "react-toastify";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";
import useApiPost from "../../../../Components/Hooks/useApiPost";

export default function CtrTable({ctrs, platformOptions, getCtrs, loading}) {
  const [ctrData, setCtrData] = useState(ctrs)
  const [changedItems, setChangedItems] = useState([])
  const deleteCtr = useDelete(apiUrls.delete.ctr)
  const [{}, updateCtr] = useApiPatch(
    apiUrls.patch.ctr,
    '',
    'autopilot.ctr',
  )

  const [{}, addCtr] = useApiPost(
    apiUrls.post.ctr(),
    '',
    'autopilot.ctr',
  )

  useEffect(() => {
    if (ctrs) {
      const ctrData = cloneDeep(ctrs)
      setCtrData(ctrData)
    }
  }, [ctrs])

  const onValueChange = (value, key, ctr, index) => {
    const ctrItems = cloneDeep(ctrData)
    let ctrItem = null
    if (ctrItems[index]) {
      ctrItem = ctrItems[index]
      if (key === 'platform') {
        ctrItems[index][key]['@id'] = `/digital_platforms/${value.value}`
        ctrItems[index][key].id = value.value
        ctrItems[index][key].name = value.label
      } else {
        ctrItems[index][key] = value
      }
    }

    setCtrData(ctrItems)

    if (ctr.id) {
      const oldCtr = ctrs.find(item => item.id === ctr.id)
      const changed = cloneDeep(changedItems)

      if (isEqual(oldCtr, ctrItem)) {
        const index = changed.indexOf(ctr.id);
        if (index > -1) {
          changed.splice(index, 1)
        }
      } else {
        if (!changed.includes(ctr.id)) {
          changed.push(ctr.id)
        }
      }

      setChangedItems(changed)
    }
  }

  const addNewItem = () => {
    const ctrItems = cloneDeep(ctrData)
    ctrItems.push({
      dateStart: new Date(),
      id: '',
      platform: {
        '@type': "DigitalPlatform",
        id: '',
        name: '',
      },
      value: 0,
    })

    setCtrData(ctrItems)
  }

  const deleteItem = ctr => {
    deleteCtr(ctr.id, () => {
      toast.success(i18n.t('common.deleted'))
      getCtrs()
    })
  }

  const saveItem = ctr => {
    if (ctr.id) {
      updateCtr(ctr.id, {
        dateStart: ctr.dateStart,
        platform: ctr.platform['@id'],
        value: ctr.value,
      }, {}, () => {
        getCtrs()
        toast.success(i18n.t('common.saved'))

        const changed = cloneDeep(changedItems)
        const index = changed.indexOf(ctr.id)
        if (index > -1) {
          changed.splice(index, 1);
        }
        setChangedItems(changed)
      })
    } else {
      addCtr({
        dateStart: ctr.dateStart,
        platform: ctr.platform['@id'],
        value: ctr.value,
      }, null, {}, true, () => {
        getCtrs()
        toast.success(i18n.t('common.saved'))
      })
    }
  }

  const data = useMemo(() => {
    const data = []

    const columns = [
      {
        key: 'id',
        editable: false,
        type: 'int',
      },
      {
        key: 'platform',
        editable: true,
        type: 'select',
        value: value => ({
          label: value.name,
          value: value.id,
        }),
        options: platformOptions,
      },
      {
        key: 'value',
        editable: true,
        type: 'int',
      },
      {
        key: 'dateStart',
        editable: true,
        type: 'date',
      },
      {
        key: 'actions',
        editable: false,
        type: 'actions',
      },
    ]

    if (ctrData) {
      for (let index in ctrData) {
        const ctr = ctrData[index]
        const item = {}
        for (let column of columns) {
          const value = column.value ? column.value(ctr[column.key]) : ctr[column.key]

          if (column.key === 'actions') {
            item[column.key] =
              <div className={'autopilot-table__buttons'}>
                <DeleteWithConfirmButton onClick={() =>
                  deleteItem(ctr)} title={i18n.t('common.delete')}/>

                {(changedItems.includes(ctr.id) || !ctr.id) &&
                  <button
                    onClick={() => saveItem(ctr)}
                    title={i18n.t('common.save')}
                  >
                    <i className="far fa-save"/>
                  </button>
                }
              </div>
            continue
          } else if (!column.editable) {
            item[column.key] = value
            continue
          }

          item[column.key] = <RowCell
            key={column.key}
            value={value}
            onChange={value => {
              onValueChange(value, column.key, ctr, index)
            }}
            code={column.key}
            type={column.type}
            options={column.options}
          />
        }

        data.push(item)
      }
    }

    return data
  }, [ctrData])

  const columns = [
    {
      title: i18n.t('autopilot.id'),
      width: 100,
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.platform'),
      width: 150,
      dataIndex: 'platform',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.dateStart'),
      width: 100,
      dataIndex: 'dateStart',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.value'),
      width: 100,
      dataIndex: 'value',
      align: 'center',
    },
    {
      title: '',
      width: 30,
      dataIndex: 'actions',
      align: 'center',
    },
  ]

  return <div className={'autopilot-table'}>
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowClassName={(record) => (record.isNew ? 'new-row' : '')}
      loading={loading}
    />
    <a className="blue-cursor-button" onClick={addNewItem}>
      <i className="fal fa-plus mr-for-icon"></i>
      {i18n.t('common.add')}
    </a>
  </div>

}