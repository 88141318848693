import React, {useEffect, useMemo, useState} from 'react';
import RowCell from "../../RowCell";
import {Table} from "antd";
import 'antd/dist/antd.css';
import i18n from "i18next";
import {cloneDeep, isEqual} from "lodash";
import useDelete from "../../../../Components/Hooks/useDelete";
import apiUrls from "../../../../ApiUrls";
import {toast} from "react-toastify";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";
import useApiPost from "../../../../Components/Hooks/useApiPost";

export default function AgeTable({ages, getAges, loading}) {
  const [ageData, setAgeData] = useState(ages)
  const [changedItems, setChangedItems] = useState([])
  const deleteAge = useDelete(apiUrls.delete.age)
  const [{}, updateAge] = useApiPatch(
    apiUrls.patch.age,
    '',
    'autopilot.age',
  )

  const [{}, addAge] = useApiPost(
    apiUrls.post.age(),
    '',
    'autopilot.age',
  )

  useEffect(() => {
    if (ages) {
      const ageData = cloneDeep(ages)
      setAgeData(ageData)
    }
  }, [ages])

  const onValueChange = (value, key, age, index) => {
    const ageItems = cloneDeep(ageData)
    let ageItem = null
    if (ageItems[index]) {
      ageItems[index][key] = value
    }

    setAgeData(ageItems)

    if (age.id) {
      const oldAge = ages.find(item => item.id === age.id)
      const changed = cloneDeep(changedItems)

      if (isEqual(oldAge, ageItem)) {
        const index = changed.indexOf(age.id);
        if (index > -1) {
          changed.splice(index, 1)
        }
      } else {
        if (!changed.includes(age.id)) {
          changed.push(age.id)
        }
      }

      setChangedItems(changed)
    }
  }

  const addNewItem = () => {
    const ageItems = cloneDeep(ageData)
    ageItems.push({
      id: '',
      min: 0,
      max: 0,
    })

    setAgeData(ageItems)
  }

  const deleteItem = age => {
    deleteAge(age.id, () => {
      toast.success(i18n.t('common.deleted'))
      getAges()
    })
  }

  const saveItem = age => {
    if (age.id) {
      updateAge(age.id, {
        min: age.min,
        max: age.max,
      }, {}, () => {
        getAges()
        toast.success(i18n.t('common.saved'))

        const changed = cloneDeep(changedItems)
        const index = changed.indexOf(age.id)
        if (index > -1) {
          changed.splice(index, 1);
        }
        setChangedItems(changed)
      })
    } else {
      addAge({
        min: age.min,
        max: age.max,
      }, null, {}, true, () => {
        getAges()
        toast.success(i18n.t('common.saved'))
      })
    }
  }

  const data = useMemo(() => {
    const data = []

    const columns = [
      {
        key: 'id',
        editable: false,
        type: 'int',
      },
      {
        key: 'min',
        editable: true,
        type: 'int',
      },
      {
        key: 'max',
        editable: true,
        type: 'int',
      },
      {
        key: 'actions',
        editable: false,
        type: 'actions',
      },
    ]

    if (ageData) {
      for (let index in ageData) {
        const age = ageData[index]
        const item = {}
        for (let column of columns) {
          const value = column.value ? column.value(age[column.key]) : age[column.key]

          if (column.key === 'actions') {
            item[column.key] =
              <div className={'autopilot-table__buttons'}>
                <DeleteWithConfirmButton onClick={() =>
                  deleteItem(age)} title={i18n.t('common.delete')}/>

                {(changedItems.includes(age.id) || !age.id) &&
                  <button
                    onClick={() => saveItem(age)}
                    title={i18n.t('common.save')}
                  >
                    <i className="far fa-save"/>
                  </button>
                }
              </div>
            continue
          } else if (!column.editable) {
            item[column.key] = value
            continue
          }

          item[column.key] = <RowCell
            key={column.key}
            value={value}
            onChange={value => {
              onValueChange(value, column.key, age, index)
            }}
            code={column.key}
            type={column.type}
            options={column.options}
          />
        }

        data.push(item)
      }
    }

    return data
  }, [ageData])

  const columns = [
    {
      title: i18n.t('autopilot.id'),
      width: 100,
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.min'),
      width: 150,
      dataIndex: 'min',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.max'),
      width: 150,
      dataIndex: 'max',
      align: 'center',
    },
    {
      title: '',
      width: 30,
      dataIndex: 'actions',
      align: 'center',
    },
  ]

  return <div className={'autopilot-table'}>
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowClassName={(record) => (record.isNew ? 'new-row' : '')}
      loading={loading}
      style={{
        width: '800px'
      }}
    />
    <a className="blue-cursor-button" onClick={addNewItem}>
      <i className="fal fa-plus mr-for-icon"></i>
      {i18n.t('common.add')}
    </a>
  </div>

}