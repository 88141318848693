import React, {useEffect, useState} from 'react'
import FilterElement from "./FilterElement";
import {isEqual} from "lodash";
import { getValuesFromUrl} from "../../utils";
import usePrevious from "../Hooks/usePrevious";
import {useHistory} from "react-router-dom";

function Filter({filters, getData, page = null, setPage = () => {}, delay = 1300}) {
  const history = useHistory()
  const [filterItems] = useState(filters)
  const [timer, setTimer] = useState(null)
  const [filterValues, setFilterValues] = useState({})
  const previous = usePrevious({filterValues});

  useEffect(() => {
    setNonSelectFilterValuesFromUrl()
  }, [])

  useEffect(() => {
    if (page) {
      setFilterValue('page', page)
    }
  }, [page])

  useEffect(() => {
    if (previous && filterValues) {
      const isAllValues = filterItems.every(filterItem => filterValues[filterItem.name] !== null
        && filterValues[filterItem.name] !== undefined)

      const isRequiredValues = filterItems
        .filter(filterItem => filterItem.required)
        .every(filterItem => {
          if (filterItem.isMulti) {
            return filterValues[filterItem.name] && filterValues[filterItem.name].length
          }

          return filterValues[filterItem.name]
        })

      if (!isRequiredValues || !isAllValues) {
        return
      }

      let isChanged = false

      for (let name in filterValues) {
        const value = filterValues[name]
        const oldValue = previous.filterValues[name]

        if (!isEqual(value, oldValue)) {
          isChanged = true
        }
      }

      if (isChanged) {
        const filterParams = getFilterParams()
        saveFilterValuesToUrl()

        if (delay) {
          if (timer) {
            clearTimeout(timer);
          }

          const timerId = setTimeout(() => {
            getData(filterParams)
          }, delay);

          setTimer(timerId)
        } else {
          getData(filterParams)
        }
      }
    }

  }, [filterValues])

  const setFilterValue = (name, value) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))

    if (Array.isArray(value) && value.length) {
      setPage(1)
    }
  }

  const saveFilterValuesToUrl = () => {
    let params = new URLSearchParams(history.location.search)

    for (let filter of filterItems) {
      if (typeof filter.getValueToUrl === 'function') {
        filter.getValueToUrl(params, filterValues[filter.name])
      }
    }

    if (page) {
      params.set('page', page)
    }

    history.push(history.location.pathname + '?' + params.toString())
  }

  const getFilterParams = () => {
    const params = new URLSearchParams()

    for(let filter of filterItems) {
      if (typeof filter.setFilterParams === 'function' && filterValues[filter.name] !== null && filterValues[filter.name] !== undefined) {
        filter.setFilterParams(params, filterValues[filter.name], filterValues)
      }
    }

    if (page) {
      params.set('page', page)
    }

    return params;
  }

  const setNonSelectFilterValuesFromUrl = () => {
    for (let filter of filterItems) {
      if (filter.type != 'select') {
        setNonSelectFilterValue(filter)
      }
    }

    if (page) {
      setFilterValue('page', page)
      setPage(page)
    }
  }

  const setNonSelectFilterValue = (filter) => {
    const values = getValuesFromUrl()
    let value = values[filter.name]

    if (typeof filter.getValueFromUrl === 'function') {
      value = filter.getValueFromUrl(values)
    }

    if (filter.type === 'radio') {
      if (Array.isArray(value)) {
        setFilterValue(filter.name, parseInt(value[0]))
      } else {
        if (value === null || value === undefined) {
          setFilterValue(filter.name, '')
        } else {
          setFilterValue(filter.name, parseInt(value))
        }
      }
    } else if (value) {
      setFilterValue(filter.name, value)
    } else {
      if (filter.type === 'text') {
        setFilterValue(filter.name, '')
      }
    }
  }

  return (
    <div className="menu-filter">
      {filterItems && filterItems.length && filterItems.map(elem => (
        <FilterElement
          key={elem.name}
          element={elem}
          value={filterValues[elem.name]}
          onChange={setFilterValue}
          setFilterValue={setFilterValue}
          filterValues={filterValues}
        />
      ))}
    </div>
  )
}

export default Filter