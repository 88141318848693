import React, {useEffect, useMemo, useState} from 'react';
import {Table} from "antd";
import 'antd/dist/antd.css';

import i18n from "i18next";

const columns = [
  {
    title: i18n.t('autopilot.id'),
    width: 100,
    dataIndex: 'id',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.product'),
    width: 200,
    dataIndex: 'product',
    align: 'center',
    editable: false,
    render: (text, record, index) => record.product.name,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: i18n.t('autopilot.platform'),
    width: 200,
    dataIndex: 'platform',
    align: 'center',
    editable: false,
    render: (text, record, index) => record.platform.name,
    sorter: {
      multiple: 2,
    },
  },
  {
    title: i18n.t('autopilot.format'),
    width: 200,
    dataIndex: 'format',
    align: 'center',
    editable: false,
    render: (text, record, index) => record.format.name,
    sorter: {
      multiple: 3,
    },
  },
  {
    title: i18n.t('autopilot.channel'),
    width: 200,
    dataIndex: 'channel',
    align: 'center',
    editable: false,
    render: (text, record, index) => record.channel.name,
    sorter: {
      multiple: 4,
    },
  },
  {
    title: i18n.t('autopilot.targeting'),
    width: 200,
    dataIndex: 'targeting',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.age'),
    width: 200,
    dataIndex: 'age',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.budget'),
    width: 200,
    dataIndex: 'budget',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.ctr'),
    width: 200,
    dataIndex: 'ctr',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.cpc'),
    width: 200,
    dataIndex: 'cpc',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.cr'),
    width: 200,
    dataIndex: 'cr',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.freq'),
    width: 200,
    dataIndex: 'freq',
    align: 'center',
    editable: false,
  },
  {
    title: i18n.t('autopilot.cpl'),
    width: 200,
    dataIndex: 'cpl',
    align: 'center',
    editable: false,
  },
]

export default function HistoricalPerformanceTable({historicalPerformances, loading, total, setPage, page,
                                                     sortParams, setSortParams}) {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: Number(page),
      pageSize: 30,
      total,
      showSizeChanger: false,
    },
  })

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: total,
      },
    });
  }, [total]);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: Number(page),
      },
    });
  }, [page])

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current)
    setTableParams({
      pagination
    })

    console.log(sorter)
    if (Array.isArray(sorter)) {
      const params = {}
      for (let sort of sorter) {
        params[sort.field] = sort.order
      }

      setSortParams({
        ...params,
      })
    } else {
      setSortParams({
        [sorter.field]: sorter.order,
      })
    }
  }

  return <div className={'autopilot-table'}>

    <Table
      columns={columns}
      dataSource={historicalPerformances}
      pagination={tableParams.pagination}
      rowClassName={(record) => (record.isNew ? 'new-row' : '')}
      loading={loading}
      onChange={handleTableChange}
      rowKey={'id'}
    />
  </div>

}