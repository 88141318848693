import React, {useEffect, useMemo, useState} from 'react';
import RowCell from "../../RowCell";
import {Table} from "antd";
import 'antd/dist/antd.css';

import i18n from "i18next";
import {cloneDeep, isEqual} from "lodash";
import useDelete from "../../../../Components/Hooks/useDelete";
import apiUrls from "../../../../ApiUrls";
import {toast} from "react-toastify";
import useApiPatch from "../../../../Components/Hooks/useApiPatch";
import DeleteWithConfirmButton from "../../../../Components/Buttons/DeleteWithConfirmButton";
import useApiPost from "../../../../Components/Hooks/useApiPost";

export default function CpvTable({cpvs, platformOptions, getCpvs, loading}) {
  const [cpvData, setCpvData] = useState(cpvs)
  const [changedItems, setChangedItems] = useState([])
  const deleteCpv = useDelete(apiUrls.delete.cpv)
  const [{}, updateCpv] = useApiPatch(
    apiUrls.patch.cpv,
    '',
    'autopilot.cpv',
  )

  const [{}, addCpv] = useApiPost(
    apiUrls.post.cpv(),
    '',
    'autopilot.cpv',
  )

  useEffect(() => {
    if (cpvs) {
      const cpvData = cloneDeep(cpvs)
      setCpvData(cpvData)
    }
  }, [cpvs])

  const onValueChange = (value, key, cpv, index) => {
    const cpvItems = cloneDeep(cpvData)
    let cpvItem = null
    if (cpvItems[index]) {
      cpvItem = cpvItems[index]
      if (key === 'platform') {
        cpvItems[index][key]['@id'] = `/digital_platforms/${value.value}`
        cpvItems[index][key].id = value.value
        cpvItems[index][key].name = value.label
      } else {
        cpvItems[index][key] = value
      }
    }

    setCpvData(cpvItems)

    if (cpv.id) {
      const oldCpv = cpvs.find(item => item.id === cpv.id)
      const changed = cloneDeep(changedItems)

      if (isEqual(oldCpv, cpvItem)) {
        const index = changed.indexOf(cpv.id);
        if (index > -1) {
          changed.splice(index, 1)
        }
      } else {
        if (!changed.includes(cpv.id)) {
          changed.push(cpv.id)
        }
      }

      setChangedItems(changed)
    }
  }

  const addNewItem = () => {
    const cpvItems = cloneDeep(cpvData)
    cpvItems.push({
      dateStart: new Date(),
      id: '',
      platform: {
        '@type': "DigitalPlatform",
        id: '',
        name: '',
      },
      value: 0,
      viewMax: 0,
      viewMin: 0,
    })

    setCpvData(cpvItems)
  }

  const deleteItem = cpv => {
    deleteCpv(cpv.id, () => {
      toast.success(i18n.t('common.deleted'))
      getCpvs()
    })
  }

  const saveItem = cpv => {
    if (cpv.id) {
      updateCpv(cpv.id, {
        dateStart: cpv.dateStart,
        platform: cpv.platform['@id'],
        value: cpv.value,
        viewMax: cpv.viewMax,
        viewMin: cpv.viewMin,
      }, {}, () => {
        getCpvs()
        toast.success(i18n.t('common.saved'))

        const changed = cloneDeep(changedItems)
        const index = changed.indexOf(cpv.id)
        if (index > -1) {
          changed.splice(index, 1);
        }
        setChangedItems(changed)
      })
    } else {
      addCpv({
        dateStart: cpv.dateStart,
        platform: cpv.platform['@id'],
        value: cpv.value,
        viewMax: cpv.viewMax,
        viewMin: cpv.viewMin,
      }, null, {}, true, () => {
        getCpvs()
        toast.success(i18n.t('common.saved'))
      })
    }
  }

  const data = useMemo(() => {
    const data = []

    const columns = [
      {
        key: 'id',
        editable: false,
        type: 'int',
      },
      {
        key: 'platform',
        editable: true,
        type: 'select',
        value: value => ({
          label: value.name,
          value: value.id,
        }),
        options: platformOptions,
      },
      {
        key: 'value',
        editable: true,
        type: 'int',
      },
      {
        key: 'viewMax',
        editable: true,
        type: 'int',
      },
      {
        key: 'viewMin',
        editable: true,
        type: 'int',
      },
      {
        key: 'dateStart',
        editable: true,
        type: 'date',
      },
      {
        key: 'actions',
        editable: false,
        type: 'actions',
      },
    ]

    if (cpvData) {
      for (let index in cpvData) {
        const cpv = cpvData[index]
        const item = {}
        for (let column of columns) {
          const value = column.value ? column.value(cpv[column.key]) : cpv[column.key]

          if (column.key === 'actions') {
            item[column.key] =
              <div className={'autopilot-table__buttons'}>
                <DeleteWithConfirmButton onClick={() =>
                  deleteItem(cpv)} title={i18n.t('common.delete')}/>

                {(changedItems.includes(cpv.id) || !cpv.id) &&
                  <button
                    onClick={() => saveItem(cpv)}
                    title={i18n.t('common.save')}
                  >
                    <i className="far fa-save"/>
                  </button>
                }
              </div>
            continue
          } else if (!column.editable) {
            item[column.key] = value
            continue
          }

          item[column.key] = <RowCell
            key={column.key}
            value={value}
            onChange={value => {
              onValueChange(value, column.key, cpv, index)
            }}
            code={column.key}
            type={column.type}
            options={column.options}
          />
        }

        data.push(item)
      }
    }

    return data
  }, [cpvData])

  const columns = [
    {
      title: i18n.t('autopilot.id'),
      width: 100,
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.platform'),
      width: 200,
      dataIndex: 'platform',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.dateStart'),
      width: 100,
      dataIndex: 'dateStart',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.viewMin'),
      width: 100,
      dataIndex: 'viewMin',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.viewMax'),
      width: 100,
      dataIndex: 'viewMax',
      align: 'center',
    },
    {
      title: i18n.t('autopilot.value'),
      width: 100,
      dataIndex: 'value',
      align: 'center',
    },
    {
      title: '',
      width: 30,
      dataIndex: 'actions',
      align: 'center',
    },
  ]

  return <div className={'autopilot-table'}>
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowClassName={(record) => (record.isNew ? 'new-row' : '')}
      loading={loading}
    />
    <a className="blue-cursor-button" onClick={addNewItem}>
      <i className="fal fa-plus mr-for-icon"></i>
      {i18n.t('common.add')}
    </a>
  </div>

}