import React, {useEffect, useState} from 'react'
import MenuClient from '../../../../Components/MenuClient'
import i18n from "i18next"
import apiUrls from "../../../../ApiUrls"
import useApiFetch from "../../../../Components/Hooks/useApiFetch"
import Filter from "../../../../Components/NewFilter/Filter";
import {groupParamsByKey} from "../../../../utils";
import {getUtcDate} from "../../../Financial/FacesTotal/Utils";
import VtrTable from "./VtrTable";
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

const dateFormat = 'Y-MM-DD'

function List() {
  const [filterParams, setFilterParams] = useState('')
  const [platformFilterOptions, setPlatformFilterOptions] = useState([])
  const [{data: vtrs, isLoading: isVtrsLoading}, getVtrs] = useApiFetch(apiUrls.get.vtrs())

  const filters = {
    dateStart: {
      type: 'date',
      placeholder: i18n.t('clients.advertising.month'),
      name: 'dateStart',
      getValueFromUrl: values => {
        if (values.dateStart && values.dateStart[0]) {
          const dateValue = getUtcDate(values.dateStart[0], dateFormat)
          return dateValue.toDate()
        }

        return ''
      },
      getValueToUrl: (params, value) => {
        params.set('dateStart', getUtcDate(value).format(dateFormat))
      },
      setFilterParams: (params, filterValue) => {
        const dateValue = getUtcDate(filterValue)
        const startDate = dateValue.format(dateFormat)
        params.append('dateStart', startDate)
      },
      format: "dd.MM.yyyy",
      filterFormat: dateFormat,
      required: true,
    },
    platform: {
      type: 'select',
      value: null,
      placeholder: i18n.t('autopilot.platform'),
      getOptionsUrlFunction: apiUrls.get.digitalPlatforms,
      name: 'platform',
      setFilterParams: (params, values) => {
        params.delete('platform.id[]')
        for (let filterValue of values) {
          params.append('platform.id[]', filterValue.value)
        }
      },
      getValueToUrl: (params, platform) => {
        if (platform && platform.length) {
          params.delete('platform.id[]')
          for (let platformItem of platform) {
            params.append('platform.id[]', platformItem.value)
          }
        } else {
          params.delete('platform.id[]')
        }
      },
      setOptions: options => setPlatformFilterOptions(options),
      isMulti: true,
      width: 300,
    },
  }

  useEffect(() => {
    if (filterParams) {
      getVtrs(getDataParams())
    }
  }, [filterParams])

  const getDataParams = () => {
    const params = {
      't[]': ['face_platform'],
    }

    let filters = {}

    if (filterParams) {
      filters = groupParamsByKey(filterParams)
    }

    return {...params, ...filters}
  }

  const setParams = (filterParams) => {
    setFilterParams(filterParams)
  }

  return (
    <Layout className="layout">
      <Header
        style={{
          padding: 0,
          top: 0,
          zIndex: 1,
          width: '100%',
          height: '120px',
          background: '#fff',
        }}
      >
        <MenuClient title={`${i18n.t('autopilot.list')} ${i18n.t('autopilot.vtr')}`}>
        </MenuClient>
        <Filter
          filters={Object.values(filters)}
          getData={setParams}
          delay={300}
        />
      </Header>
      <Content>
        <VtrTable
          vtrs={vtrs}
          platformOptions={platformFilterOptions}
          getVtrs={() => getVtrs(getDataParams())}
          loading={isVtrsLoading}
        />
      </Content>
      <Footer style={{
        background: '#fff',
      }} />
    </Layout>
  );
}

export default List;