import React, {Component} from 'react';
import {
  Link
} from "react-router-dom";
import DropDown from '../DropDown';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import i18next from 'i18next';
import {parseJwt} from "../../utils";
import Protected from "../Roles/Protected";
import keycloak from '../../keycloak'
import moment from "moment";

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

class Header extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  isLogged = () => {
    const token = keycloak.token

    if (token === null) {
      return false
    } else {
      try {
        const jwtData = parseJwt(token)
        return true
      } catch (error) {
        return false
      }
    }
  }

  constructor(props, context) {
    super(props, context);
  }

  logOut = () => {
    localStorage.removeItem("token");
    this.props.onLogoutSuccess();
    this.context.router.history.push("/");
  }

  isShow = () => {
    return !this.props.history.location.pathname.includes('plan-data')
  }

  getPreviousMonthDate = () => {
    return moment.utc().subtract(1,'months').startOf('month').format('YYYY-MM-DD')
  }

  render() {
    return (
      <>
        {
          this.isLogged() && this.isShow() &&
          <div className="dashboardHeader">
            <div className="headerNav menu-block">
              <Protected access={'AdvertisingsRead'}>
                <div className='menu-list'>
                  <Link className="vertical" to="/advertisings">
                    {i18next.t('header.advertising')}
                  </Link>
                </div>
              </Protected>

              <Protected access={'ClientsRead'}>
                <div className='menu-list'>
                  <Link className="vertical" to="/clients">
                    {i18next.t('header.partner')}
                  </Link>
                </div>
              </Protected>

              <Protected access={'FinancialRead'}>
                <div className={'menu-list'}>
                  <DropDown
                    title={<span className="text-button-label vertical">{i18next.t('header.financial_close')}</span>}
                    icon={<i className="fal fa-chevron-right"/>}
                    timeToClose={4000}
                  >
                    <Protected access={'ReconciliationWithClientRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/client-reconciliation">
                          {i18next.t('header.reconciliation_with_client')}
                        </Link>
                      </div>
                    </Protected>

                    <Protected access={'ReconciliationWithPlannerRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/planner-reconciliation">
                          {i18next.t('header.reconciliation_with_planner')}
                        </Link>
                      </div>
                    </Protected>

                    {/*<Protected access={'FinancialInvoicesRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/invoices">
                          {i18next.t('header.invoices_list')}
                        </Link>
                      </div>
                    </Protected>*/}

                    <Protected access={'FinancialContractorsRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/financial/contractors">
                          {i18next.t('header.registry_list')}
                        </Link>
                      </div>
                    </Protected>

                    <Protected access={'FinancialFinancierRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/financial/financier">
                          {i18next.t('header.financier')}
                        </Link>
                      </div>
                    </Protected>

                    <Protected access={'FinancialFinancierRead'}>
                      <div className={'dropdown-item'}>
                        <Link to={'/financial/client_invoices?date=' + this.getPreviousMonthDate()}>
                          Документы клиентам
                        </Link>
                      </div>
                    </Protected>

                    <Protected access={'FinancialCommissionsRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/financial/commissions">
                          {i18next.t('header.commissions')}
                        </Link>
                      </div>
                    </Protected>

                    {/*<Protected access={'FinancialFinancierRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/financial/month-fact/">
                          {i18next.t('header.month_fact')}
                        </Link>
                      </div>
                    </Protected>*/}

                    <Protected access={'FinancialFinancierRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/financial/closing-performance/">
                          {i18next.t('header.financial_close_performance')}
                        </Link>
                      </div>
                    </Protected>

                      {/*<Protected access={'FacesTotalEdit'}>
                          <div className={'dropdown-item'}>
                              <Link to="/financial/faces-total">
                                  {i18next.t('header.total_faces')}
                              </Link>
                          </div>
                      </Protected>*/}

                    <Protected access={'FinancialAdvertsRead'}>
                      <div className={'dropdown-item'}>
                        <Link to="/financial/report">
                          {i18next.t('header.reconciliation_data')}
                        </Link>
                      </div>
                    </Protected>
                  </DropDown>
                </div>
              </Protected>
              <Protected access={'AutopilotReferencesEdit'}>
                <div className={'menu-list'}>
                  <DropDown
                    title={<span className="text-button-label vertical">{i18next.t('header.autopilot')}</span>}
                    icon={<i className="fal fa-chevron-right"/>}
                    timeToClose={4000}
                    width={150}
                    className={'horizontal'}
                  >
                    <DropDown
                      title={<span className="text-button-label">{i18next.t('autopilot.menu_block_1')}</span>}
                      icon={<i className="fal fa-chevron-right"/>}
                      timeToClose={4000}
                    >
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/1/cpms">
                          {i18next.t('autopilot.cpm')}
                        </Link>
                      </div>
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/1/cpvs">
                          {i18next.t('autopilot.cpv')}
                        </Link>
                      </div>
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/1/ctrs">
                          {i18next.t('autopilot.ctr')}
                        </Link>
                      </div>
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/1/vtrs">
                          {i18next.t('autopilot.vtr')}
                        </Link>
                      </div>
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/1/ages">
                          {i18next.t('autopilot.age')}
                        </Link>
                      </div>
                    </DropDown>
                    <DropDown
                      title={<span className="text-button-label">{i18next.t('autopilot.menu_block_4')}</span>}
                      icon={<i className="fal fa-chevron-right"/>}
                      timeToClose={4000}
                    >
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/4/product_channels">
                          {i18next.t('autopilot.product_channel')}
                        </Link>
                      </div>
                      <div className={'dropdown-item'}>
                        <Link to="/autopilot/4/historical_performance">
                          {i18next.t('autopilot.historical_performance')}
                        </Link>
                      </div>
                    </DropDown>
                  </DropDown>
                </div>
              </Protected>
            </div>
            <div className="menu-block admin">
              <div className="menu-list">
                <DropDown direction="up"
                          title={<span className="text-button-label vertical">{this.props.user.username}</span>}
                          icon={<i className="fal fa-chevron-right text-button-icon icon-status"/>}
                          timeToClose={4000}
                >
                  <div className="dropdown-item">
                    <button className="btn-transperent" onClick={this.logOut}>
                      {i18next.t('header.exit')}
                    </button>
                  </div>
                </DropDown>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

export default connect(mapStateToProps)(Header);
