import React from 'react'
import { Input } from 'antd';

function TextFilter({element, value, onChange}) {
  return (
    <span className={'filter-item ' + element.className}>
      <Input
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        placeholder={element.placeholder}
        allowClear
      />
    </span>
  )
}

export default TextFilter